import React from 'react'
import { graphql } from 'gatsby'
import Image from '../components/Image'
import Layout from '../components/Layout'
import SuperheroBox from '../components/SuperheroBox'

export default function Contact({ data }) {
  const seo = {
    title: 'Contact',
    slug: 'contact',
    image: data.image,
    description: "Let's communicate!",
  }

  return (
    <Layout colorScheme="bleu" layout="basic" seo={seo}>
      <article>
        <SuperheroBox>
          <Image data={data.image} />
        </SuperheroBox>
        <h1>Get in Touch!</h1>
        <p>
          Want to know what we could do for and with you? Let's meet up for a
          coffee!
        </p>
        <p>
          Talk to <a href="mailto:erik@unsworn.org">Erik</a> at +46737038251,{' '}
          <a href="mailto:magnus@unsworn.org">Magnus</a> at +46707493818, say{' '}
          <a href="mailto:hello@unsworn.org">hello</a> or come for a coffee at
          our studios at Norra&nbsp;Grängesbergsgatan 20 in beautiful Malmö,
          Sweden
        </p>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query ContactQuery {
    image: file(
      relativePath: { eq: "unsworn/megaphonebooth/mb_auditory_audit.jpg" }
    ) {
      ...superheroImage
    }
  }
`
